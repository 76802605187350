<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">
  <!-- Navbar brand -->
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item">
      <a class="navbar-brand" [routerLink]="['/']">
        <span class="brand-logo">
          <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" width="36" />
        </span>
        <h2 class="brand-text mb-0">{{ coreConfig.app.appName }}</h2>
      </a>
    </li>
  </ul>
  <!--/ Navbar brand -->
</div>

<div class="navbar-container d-flex content">
  <div class="bookmark-wrapper d-flex align-items-center">
    <!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
    <ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig.layout.menu.hidden">
      <li class="nav-item">
        <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
          <span [data-feather]="'menu'" [class]="'ficon'"></span>
        </a>
      </li>
    </ul>
    <!--/ Menu Toggler -->

    <h3 class="brand-text text-primary">CRM ArchipelMixed
      <span> | </span>
      <a routerLink="/customer">Liste des clients</a>
    </h3>

    <!-- Bookmark -->
    <!--<app-navbar-bookmark></app-navbar-bookmark>-->
    <!--/ Bookmark -->
  </div>
  <ul class="nav navbar-nav align-items-center ml-auto">
    <!-- Language selection -->
    <!--<li ngbDropdown class="nav-item dropdown dropdown-language">
      <a class="nav-link dropdown-toggle" id="dropdown-flag" ngbDropdownToggle>
        <i class="flag-icon flag-icon-{{ languageOptions[_translateService.currentLang].flag }}"></i
        ><span class="selected-language">{{ languageOptions[_translateService.currentLang].title }}</span></a
      >
      <div ngbDropdownMenu aria-labelledby="dropdown-flag">
        <a *ngFor="let lang of _translateService.getLangs()" ngbDropdownItem (click)="setLanguage(lang)">
          <i class="flag-icon flag-icon-{{ languageOptions[lang].flag }}"></i> {{ languageOptions[lang].title }}
        </a>
      </div>
    </li>-->
    <!--/ Language selection -->

    <!-- Toggle skin -->
    <!--<li class="nav-item d-none d-lg-block">
      <a class="nav-link nav-link-style btn" (click)="toggleDarkSkin()">
        <span [ngClass]="currentSkin === 'dark' ? 'icon-sun' : 'icon-moon'" class="ficon font-medium-5 feather"></span>
      </a>
    </li>-->
    <!--/ Toggle skin -->

    <!-- Search -->
    <!-- <app-navbar-search></app-navbar-search> -->
    <li>
      <div class="input-group">
        <input type="text" [(ngModel)]="phone" class="form-control" placeholder="Saisir un numéro"
          aria-describedby="button-addon2" />
        <div class="input-group-append" id="button-addon2">
          <button class="btn btn-outline-primary" type="button" rippleEffect (click)="searchCustomer()"><i
              data-feather="search"></i></button>
        </div>
      </div>
    </li>
    <!--/ Search -->

    <!-- Cart -->
    <!--<app-navbar-cart></app-navbar-cart>-->
    <!--/ Cart -->

    <!-- Notification -->
    <app-navbar-notification></app-navbar-notification>
    <!--/ Notification -->

    <!-- User Dropdown -->
    <li ngbDropdown class="nav-item dropdown-user">
      <a class="nav-link dropdown-toggle dropdown-user-link" id="dropdown-user" ngbDropdownToggle
        id="navbarUserDropdown" aria-haspopup="true" aria-expanded="false">
        <ng-container *ngIf="this.currentUser">
          <div class="user-nav d-sm-flex d-none">
            <span class="user-name font-weight-bolder">{{ this.currentUser.username }} </span>
            <span class="user-status">{{this.currentUser.role == 'ROLE_USER' ? 'Admin': this.currentUser.role ==
              'ROLE_MANAGER' ? 'Manager': this.currentUser.role == 'ROLE_COM' ? 'Com': 'Vendeur'}}</span>
          </div>
          <span class="avatar"><img class="round" src="assets/logo_no_text.jpg" alt="avatar" height="40"
              width="40" /><span class="avatar-status-online"></span></span>
        </ng-container>
      </a>
      <div ngbDropdownMenu aria-labelledby="navbarUserDropdown" class="dropdown-menu dropdown-menu-right">
        <a ngbDropdownItem routerLink="/profile"><span [data-feather]="'user'" [class]="'mr-50'"></span>
          Profile</a>
        <div class="dropdown-divider"></div>
        <a ngbDropdownItem (click)="logout()"><span [data-feather]="'power'" [class]="'mr-50'"></span> Déconnexion</a>
      </div>
    </li>
    <!--/ User Dropdown -->
  </ul>
</div>

<!-- Customers Modal -->
<ng-template #modalCustomers let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="myModalLabel1">
      List clients avec ce numéro "{{phone}}"
    </h4>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>Téléphone</th>
            <th>Téléphone du parent</th>
            <th>Nom</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let customer of customers">
            <td>
              <span class="font-weight-bold">{{customer.phone}}</span>
            </td>
            <td>{{customer.parent_phone}}</td>
            <td>
              {{customer.first_name}} {{customer.last_name}}
            </td>
            <td class="overflow-hidden">
              <button type="button" (click)="modal.close()" routerLink="/customer/{{customer.id}}/detail"
                class="btn btn-raised btn-outline-primary btn-sm" rippleEffect>
                <i data-feather="file-text"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.close()" rippleEffect>
      Fermer
    </button>
  </div>
</ng-template>
<!-- / Customers Modal -->